module.exports = {
    toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },
    downloadB64PDF(data, filename) {
        console.log("creating <a>");
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        setTimeout(() => {
            console.log("clicking a");
            downloadLink.click();
            setTimeout(() => {
                console.log("removing a");
                downloadLink.remove();
            }, 1000);
        }, 500);
    }
}