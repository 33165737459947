<template>
  <div>
    <section class="section-1">
      <h1>
        <span class="blue">Dein individuelles Skript.</span> Schemata und
        Definitionen. Kostenlos.
      </h1>

      <div>
        <Search />
      </div>
    </section>

    <Chatbot />
    <HomeContent />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

h1,
h2 {
  margin: 60px 0px;
  font-size: 3em;
  text-align: center;
  color: $darkBlue;
  @media screen and (max-width: 640px) {
    font-size: 1.7em;
  }
}

.blue {
  color: $blue;
  display: block;

  @media screen and (max-width: 640px) {
    padding: 0.1em;
  }
}

.section-1 {
  div {
    margin-bottom: 100px;
  }
}
</style>

<script>
import Search from "../components/Search.vue";
import HomeContent from "../components/HomeContent.vue";
import Chatbot from "../components/Chatbot.vue";

export default {
  name: "Home",
  components: {
    Search,
    HomeContent,
    Chatbot,
  },
};
</script>
