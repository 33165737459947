<template>
        <div id="form"></div>
</template>

<style lang="scss">
  #form {
    height: 100vh;
    z-index: 999;
  }
  .box {
    padding: 10em 5em;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .wrapper-terms {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 90%;
    height: 50%;
    border-radius: 8px;
    margin: 5%;
    padding: 0;
  }
  .wrapper-terms-text {
    display: flex;
    flex-direction: column;
    width: 362px;
    height: 278px;
    border-radius: 8px;
    margin: auto;
  }
  .terms-tittle {
    text-align: center;
    color: #002060;
    font-size: 2.5em;
  }
  .terms-tittle-2 {
    text-align: center;
    color: #002060;
  }
  .login-button-terms {
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 250px;
    height: 60px;
    font-size: 1em;
    background-color: #f5f7ff;
    color: #002060;
    justify-content: center;
    transition: 0.3s;
  }
  .login-button-terms:hover{
    background: #f2f2f2;
    }
  a {
    text-decoration: none;
    color: #002060;
  }
  @media screen and (max-width: 640px) {
    .wrapper-terms-text {
      font-size: 0.8em;
      padding: 0;
      height: 200px;
    }
  }
</style>

<script>
  import axios from "axios";
  import { createWidget } from '@typeform/embed'
  import '@typeform/embed/build/css/widget.css'

  export default {
    name: "TypeForm",
    components: {},
    methods: {
      async submitForm()  {
       await axios
          .get(`${process.env.VUE_APP_BACKEND_URL}/user/registrationForm`,{ withCredentials: true})
          .then(() => {
            setTimeout(() => {
              this.$router.push('/dashboard');
            }, 500);
          })
          .catch((error) => console.log("error", error));
      },
    },
    mounted() {
      const email =  this.$store.state.user && this.$store.state.user.email;
      const userId = this.$store.state.user && this.$store.state.user._id;
      const registrationFormCompleted = this.$store.state.user && this.$store.state.user.registrationFormCompleted;

      if(registrationFormCompleted) {
        this.$router.push('/dashboard');
      }

      if(email && userId) {
      const {unmount} = createWidget('qvUpio7v',
        {
          container: document.querySelector('#form'),
          hidden: { email: email, user_id: userId },
          disableScroll: true,
          onSubmit: () => {
            unmount();
            this.submitForm();
          },
        });
     }
    },
  };
</script>
