<template>
  <section class="section-1">
    <div class="item">
      <div class="flex">
        <div>
          <h3>{{ item.title }}</h3>
          <h4>Definition</h4>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <h2>Enthalten in:</h2>
    </div>
    <div class="parents">
      <div class="parent" v-if="item.topic">
        <div class="parent_title">
          <AddToBasketBtn class="basketBtn" :item="item" />
          <h2>{{ item.topic.title }}</h2>
          <h4>Definitionen</h4>
        </div>
        <div class="parent_children">
          <div
            class="parent_children_item"
            v-for="definition in item.topic.definitions"
            :key="definition.id"
          >
            <h3>{{ definition.title }}</h3>
            <p>{{ definition.description }}</p>
          </div>
        </div>
      </div>
      <div class="parent" v-if="item.schema">
        <div class="parent_title">
          <AddToBasketBtn class="basketBtn" :item="item" />
          <h2>{{ item.schema.title }}</h2>
        </div>
        <div class="parent_children">
          <div
            class="parent_children_item"
            v-for="definition in item.schema.definitions"
            :key="definition.id"
          >
            <h3>{{ definition.title }}</h3>
            <p>{{ definition.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';
.section-1 {
  padding: 4em 8em;
  border-radius: 7px;
  margin-bottom: 60px;

  @media screen and (max-width: 640px) {
    padding: 1em;
  }
}

section {
  padding: 1em;
  color: $darkBlue;
  letter-spacing: 0.007em;
  line-height: 25px;

  div {
    position: relative;
  }
}

.item {
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  color: $darkBlue;
  margin-left: 35px;
  margin-bottom: 4em;

  h3 {
    font-size: 2.5em;
    margin-bottom: 10px;
    user-select: none;
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }
  h2 {
    color: $blue;
    position: relative;
    max-width: 800px;
    flex-basis: auto;
    flex-grow: 1;
    width: 100%;

    margin-bottom: 1em;
  }

  p {
    margin: 0px;
    width: 90%;
  }

  span {
    position: absolute;
    left: -35px;
    top: 3px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.parents {
  background: #fff;
  padding: 4em;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border-radius: 7px;
  margin-bottom: 60px;

  @media screen and (max-width: 640px) {
    padding: 1em;
  }
  .parent {
    &_title {
      h2 {
        margin: 0;
        padding-right: 2rem;
      }
      h4 {
        margin: 0px;
        opacity: 0.4;
      }
      position: relative;
      background: $background;
      padding: 4em;
      box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
      border-radius: 7px;
      margin-bottom: 60px;

      @media screen and (max-width: 640px) {
        padding: 1em;
      }
    }
    &_children {
      h3,
      p {
        margin: 0;
      }
      &_item {
        margin-bottom: 1rem;
      }
    }
  }
}
.basketBtn {
  position: absolute;
  right: 45px;
  top: 80px;
  z-index: 2;

  @media screen and (max-width: 640px) {
    right: 17px;
    top: 40px;
  }
}
</style>

<script>
// import axios from 'axios';
import AddToBasketBtn from '../components/AddToBasketBtn.vue';

export default {
  name: 'Definition',
  components: {
    AddToBasketBtn,
  },
  data() {
    return {
      item: null,
      parsedPreview: '',
      loader: null,
    };
  },
  methods: {
    parseCustomTags(line, formatTag, htmlTag) {
      const parts = line.split(formatTag);
      if (parts.length > 1) {
        line = parts[0];
        for (let i = 1; i < parts.length; i++) {
          line += (i % 2 == 1 ? `<${htmlTag}>` : `</${htmlTag}>`) + parts[i];
        }
        if (parts.length % 2 == 0) {
          line += `</${htmlTag}>`;
        }
      }
      return line;
    },
  },
  async mounted() {
    this.loader = this.$loading.show({});
    // this.item = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/getPDF?id=${this.$route.params.id}`)).data;
    this.item = await this.$api
      .get(`/definitions/${this.$route.params.id}`, {
        params: {
          populate: {
            topic: { populate: ['definitions'] },
            schema: {},
          },
        },
      })
      .then((response) => {
        const transformedData = this.$find.transform(response.data);
        console.log(transformedData);
        return transformedData;
      });

    this.loader.hide();

    const parsedLines = [];

    //Parse Document Description Format
    const lines = this.item.description.split('\n');
    for (let i in lines) {
      let line = lines[i];

      if (line == '') {
        parsedLines.push('<br/>');
        continue;
      }

      if (i == 0) {
        line = this.parseCustomTags(line, '%', 'h1');
      } else {
        line = this.parseCustomTags(line, '%', 'b');
      }
      line = this.parseCustomTags(line, '&', 'i');

      //line = line.replace("TABTAB", `<span style="margin-left: 2em; margin-right: 0.25em;"></span>`);
      //line = line.replace("TAB", `<span style="margin-left: 1em; margin-right: 0.25em;"></span>`);
      if (line.startsWith('TAB')) {
        const twoTabs = line.startsWith('TABTAB');
        line = line.replace('TAB', '').replace('TAB', '');
        line = `<p style="margin-left: ${twoTabs ? 2 : 1}em">${line}</p>`;
      } else {
        const hasMargin = line.includes('<b>');
        line = `<span ${
          hasMargin ? 'style="margin-bottom: 0.25em"' : ''
        }>${line}</span>`;
      }

      parsedLines.push(line);
    }
    this.parsedPreview = parsedLines.join('\n');
  },
};
</script>
