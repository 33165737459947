<template>
    <FlatIcon icon="basket" class="icon" @click.native="addToBasket()" />
</template>

<style lang="scss" scoped>
.icon {
    width: 40px;
    cursor: pointer;
}
</style>

<script>
import FlatIcon from "./FlatIcon.vue";

export default {
    name: "AddToBasketBtn",
    props: ["item"],
    components: {
        FlatIcon
    },
    methods: {
        addToBasket() {
            const itemID = this.item._id;
            if (this.$store.state.basket.items.find((i) => i._id == itemID)) {
                this.$toast.open({
                    message: `"${this.item.name}" ist bereits im Warenkorb`,
                    type: "warning",
                });
                return;
            }
            this.$store.commit("addToBasket", this.item);
            this.$toast.open(`"${this.item.name}" wurde zum Warenkorb hinzugefügt`);
        },
    }
}
</script>