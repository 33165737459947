<template>
  <section>
    <div>
      <AddToBasketBtn class="basketBtn" :item="item" />
      <div class="preview" v-html="parsedPreview"></div>
    </div>
  </section>
</template>

<style scoped>
/* Vue Css Loader Magic (to access the v-html content) */
.preview >>> h1 {
  margin: 0px;
}
.preview >>> p {
  margin: 0px;
}

.preview >>> b {
  margin-bottom: 1em;
}

.preview >>> span {
  display: block;
}
</style>

<style lang="scss" scoped>
@import '../assets/css/_variables';

section {
  padding: 1em;
  display: flex;
  justify-content: center;
  color: $darkBlue;
  letter-spacing: 0.007em;
  line-height: 25px;

  div {
    position: relative;
  }
}

.preview {
  max-width: 600px;
  background: #fff;
  padding: 4em;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border-radius: 7px;
  margin-bottom: 60px;

  @media screen and (max-width: 640px) {
    padding: 1em;
  }
}

.basketBtn {
  position: absolute;
  right: 45px;
  top: 80px;
  z-index: 2;

  @media screen and (max-width: 640px) {
    right: 17px;
    top: 40px;
  }
}
</style>

<script>
// import axios from 'axios';
import AddToBasketBtn from '../components/AddToBasketBtn.vue';

export default {
  name: 'SchemePreview',
  components: {
    AddToBasketBtn,
  },
  data() {
    return {
      item: null,
      parsedPreview: '',
      loader: null,
    };
  },
  methods: {
    parseCustomTags(line, formatTag, htmlTag) {
      const parts = line.split(formatTag);
      if (parts.length > 1) {
        line = parts[0];
        for (let i = 1; i < parts.length; i++) {
          line += (i % 2 == 1 ? `<${htmlTag}>` : `</${htmlTag}>`) + parts[i];
        }
        if (parts.length % 2 == 0) {
          line += `</${htmlTag}>`;
        }
      }
      return line;
    },
  },
  async mounted() {
    this.loader = this.$loading.show({});
    // this.item = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/getPDF?id=${this.$route.params.id}`)).data;
    this.item = await this.$api
      .get(`/schemata/${this.$route.params.id}`, { params: {} })
      .then((response) => {
        const transformedData = this.$find.transform(response.data);
        console.log(transformedData);
        return transformedData;
      });

    this.loader.hide();

    const parsedLines = [];

    //Parse Document Description Format
    const lines = this.item.description.split('\n');
    for (let i in lines) {
      let line = lines[i];

      if (line == '') {
        parsedLines.push('<br/>');
        continue;
      }

      if (i == 0) {
        line = this.parseCustomTags(line, '%', 'h1');
      } else {
        line = this.parseCustomTags(line, '%', 'b');
      }
      line = this.parseCustomTags(line, '&', 'i');

      //line = line.replace("TABTAB", `<span style="margin-left: 2em; margin-right: 0.25em;"></span>`);
      //line = line.replace("TAB", `<span style="margin-left: 1em; margin-right: 0.25em;"></span>`);
      if (line.startsWith('TAB')) {
        const twoTabs = line.startsWith('TABTAB');
        line = line.replace('TAB', '').replace('TAB', '');
        line = `<p style="margin-left: ${twoTabs ? 2 : 1}em">${line}</p>`;
      } else {
        const hasMargin = line.includes('<b>');
        line = `<span ${
          hasMargin ? 'style="margin-bottom: 0.25em"' : ''
        }>${line}</span>`;
      }

      parsedLines.push(line);
    }
    this.parsedPreview = parsedLines.join('\n');
  },
};
</script>
