<template>
  <div class="item">
    <span
      v-if="data.content.length && data.type != 'schema'"
      @click="preview(data)"
    >
      <Arrow :direction="showChildren ? 'up' : 'down'" />
    </span>

    <div class="flex">
      <div>
        <h3
          :class="{
            root:
              data.type == null || data.type == 'code' || data.type == 'book',
          }"
          @click="preview(data)"
        >
          {{ data.title }}
        </h3>

        <div v-if="['schema', 'topic'].includes(data.type)">
          <h4 v-if="data.content.length == 0 && data.type != 'schema'">
            {{ data.type }}
          </h4>
          <h4 v-else>
            {{ data.type == 'topic' ? 'Defintionen' : 'Schemata' }}
          </h4>
        </div>
      </div>
      <AddToBasketBtn
        :item="data"
        v-if="['schema', 'topic'].includes(data.type)"
      />
    </div>

    <p v-if="data.description">{{ data.description }}</p>

    <div
      v-if="data.content.length"
      class="children"
      :class="{ show: showChildren, noIndent: data.type == 'topic' }"
    >
      <FullHierarchySearchResult
        v-for="child in data.content"
        :data="{ ...child, content: child.content || [] }"
        :key="child.id"
      />
    </div>
  </div>
</template>
<script>
import AddToBasketBtn from './AddToBasketBtn.vue';
import Arrow from './Arrow.vue';

export default {
  name: 'FullHierarchySearchResult',
  components: {
    AddToBasketBtn,
    Arrow,
  },
  props: ['data'],
  data() {
    return {
      showChildren: false,
      bookData: null,
    };
  },

  methods: {
    preview(document) {
      if (document.type == 'schema') {
        this.$router.push('/schema/' + document.id);
        return;
      }
      if (document.type == 'definition') {
        this.$router.push('/definition/' + document.id);
        return;
      }
      if (document.type == 'case') {
        this.$router.push('/case/' + document.id);
        return;
      }

      this.showChildren = !this.showChildren;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/_variables';

.item {
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  margin-top: 1em;
  width: 100%;

  color: $darkBlue;

  h3 {
    font-size: 1.25em;
    margin: 0px;
    cursor: pointer;
    user-select: none;

    &.root {
      font-size: 1.5em;
    }
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }

  span {
    position: absolute;
    left: -25px;
    top: 5px;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
  }

  .children {
    display: none;
    padding-left: 2em;

    &.noIndent {
      padding-left: 0px;
    }

    p {
      width: 90%;
      margin: 0px;
    }
  }

  .show {
    display: block;
    margin-bottom: 2em;
  }
}
</style>
