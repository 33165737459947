<template>
    <div>
      <div v-if="user" class="wrapper">
        <div class="welcome-wrapper">
          <div class="welcome-text">
            <h1 class="welcome-title">Willkommen</h1>
            <div class="welcome-description">
              <p>
                Wir hoffen, dass es Dir gut geht und Du einen produktiven Tag hast.
              </p>
            </div>
          </div>
          <div class="getBackButton">
            <router-link to="/home">
                <button>Los geht's</button>
            </router-link>
           </div>
        </div>
      </div>
      <div class="wrapper1">
        <section>
          <span v-if="user">
            <button class="logout-button" @click="logout">Logout</button>
          </span>
        </section>
      </div>
    </div>
  </template>

  <style lang="scss">
  .wrapper {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    border-radius: 8px;
    width: 90%;
    padding: 40px 0;
    margin-top: 0px;
    margin: 70px;
    margin-top: 0px;
    gap: 70px;
  }
   
  .welcome-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
    width: 889px;
    height: 450px; /* before it was 692px*/
  }
  
  .getBackButton{
    display:block;
    text-align: center;
   }
  
  .premium-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
    width: 540px;
    height: 692px;
    gap: 20px;
  }
  .upgrade-wrapper {
    justify-content: center;
    width: 275px;
    height: 335px;
    margin-top: 10px;
    background-color: #f5f7ff;
    border-radius: 8px;
    flex-direction: column;
    color: #002060;
  }
  .wrapper-manage-billing {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 26px;
    gap: 50px;
  }
  .box-downloads-wrapper {
    display: flex;
    justify-content: space-evenly;
    color: #002060;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    gap: 50px;
  }
  .downloads-wrapper,
  .downloads-wrapper-2 {
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #f5f7ff;
    margin-top: -60px;
    justify-content: center;
    color: #002060;
    margin-top: 20px;
  }
  .welcome-text {
    height: 40%;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 100px;
    color: #002060;
  }
  .premium-text {
    margin-top: 100px;
    color: #002060;
  }
  .welcome-name {
      margin: 0;
  }
  .upgrade-text {
    margin: 50px 30px 30px 30px;
    text-align: justify;
  }
  .billing-button {
    display: flex;
    justify-content: center;
    font-size: 1.1em;
  }
  .downloads-text {
    text-align: center;
  }
  .downloads-number {
    font-size: 46px;
    margin: 0;
  }
  .wrapper-section2 {
    padding: 20px 0px;
    margin: 20px;
    color: #002060;
    font-size: 25px;
  }
  .wrapper-section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:  8px;
    // background: white;
    padding: 20px 0px;
    margin: 20px;
    height: 300px;
    width: 500px;
  }
  .wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .welcome-title {
    margin-bottom: 0;
  }
  .section2text {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .logout-button {
    margin: 100px 40px;
    font-size: 1.2em;
    
  }
  .button-rates{
  text-align: center;
  margin: 20px 20px;
    font-size: 1em;
    transition: all .3s cubic-bezier(0.67, 0.17, 0.40, 0.83);
    background: dodgerblue;
    border-radius: 6px;
    transition: all .3s cubic-bezier(0.67, 0.17, 0.40, 0.83);
  }
  .button-rates:hover{
    background: mediumseagreen;
  }
  .button-rates svg {
    width: 20px;
    align-self: center;
    transform: rotate(180deg);
    transition: all .3s;
  }
  .welcome-description {
    p {
      margin: 0;
      font-size: 1.1em;
    }
  }
  .button__circle {
    display: flex;
    flex-direction: column;
    width: 35px;
    height: 35px;
    padding: 0 !important;
    background: mediumseagreen;
    transform: rotate(-180deg);
  }
  .button:hover {
    cursor: pointer;
  }
  .tick {
    color: white;
    transition: all .6s;
  }
  .wrapper-icon-star {
    display: flex;
    justify-content: center;
    gap: 25px;
  }
  .text-area{
          resize: none;
          height: 300px;
          width: 500px;
          border-style: none;
          border-radius: 8px;
          padding: 20px;
  }
  .section3text{
    display: flex;
    flex-direction: column;
  }
  .wrapper-button{
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      flex-wrap: wrap;
      margin: auto;
    }
  }
  @media screen and (max-width: 640px) {
    .downloads-wrapper,
    .downloads-wrapper-2 {
      height: 120px;
      width: 120px;
    }
  }
  @media screen and (max-width: 640px) {
    .downloads-number {
      font-size: 1.2em;
    }
    .downloads-text {
      font-size: 0.9em;
    }
  }
  @media screen and (max-width: 640px) {
    .welcome-text {
      margin-left: 0;
      text-align: center;
      margin-top: 75px;
    }
  }
  @media screen and (max-width: 640px) {
    .welcome-name,
    .premium-text {
     
      margin-top: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .premium-text {
     
      margin: -22px
    }
  }
  @media screen and (max-width: 640px) {
    .upgrade-text  {
     
      margin-top: 35px;
    }
  }
  @media screen and (max-width: 640px) {
    .welcome-description {
      margin-top: 1.2em;
      p {
        font-size: 0.9em;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .welcome-wrapper {
      justify-content: center;
      height: 400px;
    }
  }
  @media screen and (max-width: 640px) {
    .premium-wrapper {
      justify-content: center;
      height: 400px;
    }
  }
  @media screen and (max-width: 640px) {
    .wrapper-manage-billing {
      gap: 5px;
    }
  }
  @media screen and (max-width: 640px) {
    .upgrade-wrapper {
      height: 250px;
    }
  }
  @media screen and (max-width: 640px) {
    .section2text {
      font-size: 0.7em;
    }
  }
  @media screen and (max-width: 640px) {
    .wrapper-icon-star {
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 640px) {
    .box-downloads-wrapper {
         margin-top: 45px;
    }
  }
  // @media screen and (max-width: 640px) {
  //       .button-rates{
  //     }
  // }
  @media screen and (max-width: 640px) {
    .text-area {
      height: 200px;
      width: 300px;
    }
  }
  @media screen and (max-width: 640px) {
    .button__circle {
      width: 35px !important;
      height: 35px !important;
      font-size: 0.9em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-rates {
         width: 50px;
      height: 30px;
      font-size: 0.9em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .user-plan {
    text-transform: capitalize;
  }
  </style>
  <script>
  export default {
    name: "Dashboard",
    methods: {
      logout() {
        this.$store.commit("setUser", null);
        window.open(`${process.env.VUE_APP_BACKEND_URL}/auth/logout`, "_self");
      },
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      }
    }
  };
  </script>
