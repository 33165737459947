var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section-2"},[_c('h2',[_vm._v("So einfach")]),_c('div',{staticClass:"cards"},[_c('div',[_c('span',{staticClass:"counter"},[_vm._v("1")]),_c('FlatIcon',{attrs:{"icon":"adjustment"}}),_c('h3',[_vm._v("Suchen und Finden")]),_vm._m(0)],1),_c('div',[_c('span',{staticClass:"counter"},[_vm._v("2")]),_c('FlatIcon',{attrs:{"icon":"basket"}}),_c('h3',[_vm._v("Individualität")]),_vm._m(1)],1),_c('div',[_c('span',{staticClass:"counter"},[_vm._v("3")]),_c('FlatIcon',{attrs:{"icon":"download"}}),_c('h3',[_vm._v("Download")]),_vm._m(2)],1)])]),_c('section',{staticClass:"section-3"},[_c('div',{staticClass:"main"},[(!_vm.isMobile())?_c('div',[_c('PhoneVideo')],1):_vm._e(),_vm._m(3)]),_c('div',{staticClass:"bottom"},[_c('div',[_c('button',{on:{"click":function($event){return _vm.scrollTop()}}},[_vm._v("LOS GEHT'S")])]),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Suche Dir aus, was Du lernen möchtest. "),_c('b',[_vm._v("Definitionen und Schemata")]),_vm._v(" aus Zivil-, Straf- und Öffentlichem Recht vom "),_c('b',[_vm._v("1. Semester")]),_vm._v(" bis zum "),_c('b',[_vm._v("1. Staatsexamen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Lege Schemata und Definitionen in den Korb und "),_c('b',[_vm._v("individualisiere")]),_vm._v(" Dein Inhaltsverzeichnis. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Lade Dir Dein eigenes Skript "),_c('b',[_vm._v("kostenlos")]),_vm._v(" herunter und lerne wo und wann Du willst. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("So schnell")]),_c('p',[_vm._v("Klick.")]),_c('p',[_vm._v("Klick.")]),_c('p',[_vm._v("Und Fertig.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Kostenlos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-4"},[_c('h2',{staticClass:"title-demo"},[_vm._v("Demo-Skript")]),_c('div',{staticClass:"main"},[_c('a',{attrs:{"href":"/heyJura_Demo-Skript.pdf","download":"heyJura_Demo-Skript.pdf"}},[_c('img',{staticClass:"demo-logo",attrs:{"src":require("../assets/img/Demo-Skript-Icon_centered_02.png")}})])])])
}]

export { render, staticRenderFns }