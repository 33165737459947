<template>
  <div class="search">
    <SearchBox class="searchBox" @searchQueryChange="onSearchQueryChange" />
    <div class="searchResults">
      <SearchResult
        v-for="searchResult in matchedResults"
        :key="searchResult.id"
        :data="searchResult"
      />
    </div>
    <div class="searchResults" v-if="similarResults.length">
      <h2>Ähnlich:</h2>
      <SearchResult
        v-for="searchResult in similarResults"
        :key="searchResult._id"
        :data="searchResult"
        :closed="true"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

.search {
  padding: 0px 2em;
}

.searchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.searchBox {
  margin-bottom: 50px;

  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
}

h2 {
  color: $blue;
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  margin-left: 35px;
  margin-bottom: 1em;
}
</style>

<script>
// import axios from 'axios';
import SearchBox from '../components/SearchBox.vue';
import SearchResult from '../components/SearchResult.vue';

export default {
  name: 'Search',
  data() {
    return {
      matchedResults: [],
      similarResults: [],
    };
  },
  components: {
    SearchBox,
    SearchResult,
  },
  methods: {
    async onSearchQueryChange(event) {
      if (event.searchQuery == '') {
        this.matchedResults = [];
        this.similarResults = [];
        return;
      }

      await this.$api
        .post('/search', { query: event.searchQuery })
        .then((response) => {
          console.log(response);
          const transformedData = response.data;
          // console.log(transformedData);
          this.matchedResults = transformedData;
        })
        .catch((error) => {
          console.log(error);
          this.matchedResults = [];
          this.similarResults = [];
          this.$toast.open({
            message: 'Suche fehlgeschlagen',
            type: 'error',
          });
        });

      // const data = (
      //   await axios.get(
      //     `${process.env.VUE_APP_BACKEND_URL}/search?searchQuery=${
      //       event.searchQuery
      //     }${event.type && event.type != '' ? '&type=' + event.type : ''}`
      //   )
      // ).data;

      // if (data.error) {
      //   this.matchedResults = [];
      //   this.similarResults = [];
      //   this.$toast.open({
      //     message: 'Suche fehlgeschlagen',
      //     type: 'error',
      //   });
      // } else {
      //   this.matchedResults = data.matched;
      //   this.similarResults = data.similar;
      // }
    },
  },
  async mounted() {
    // await this.$api
    //   .get('/rights?populate=deep,10', {
    //     params: {
    //       // populate: [
    //       //   {
    //       //     path: 'codes',
    //       //     populate: [
    //       //       {
    //       //         path: 'books',
    //       //         populate: [
    //       //           {
    //       //             path: 'schematas',
    //       //           },
    //       //           {
    //       //             path: 'topics',
    //       //             populate: [
    //       //               {
    //       //                 path: 'definitions',
    //       //               },
    //       //             ],
    //       //           },
    //       //         ],
    //       //       },
    //       //     ],
    //       //   },
    //       // ],
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
};
</script>
