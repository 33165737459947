<template>
  <footer>
    <div class="flex">
      <div class="text">
        <h2>Jura wird einfacher</h2>
        <p>
          Wir sind eine Online-Lernplattform, die Jurastudent:innen kostenlos
          Definitionen und Schemata vom 1. Semester bis zum 1. Staatsexamen
          bereitstellt. Selbstverständlich wird unser Lernangebot stetig
          erweitert. Zur Zeit bieten wir folgende Lerninhalte an: BGB AT,
          Schuldrecht AT, Schuldrecht BT, Sachenrecht, Grundrechte,
          Verfassungsprozessrecht, Verwaltungsprozessrecht, Strafrecht AT und
          BT. Dabei ist uns wichtig, dass wir selbstverständlich auf hohem
          wissenschaftlichen Niveau arbeiten, unsere Lerninhalte dennoch auf
          Anhieb verständlich sind. Das erspart Stress und Arbeit, denn
          studieren kann auch einfacher sein. Suche Dir Deine Inhalte zusammen,
          sortiere sie nach eigener Präferenz und lade alle Unterlagen in einer
          geschlossenen PDF herunter. So geht Jura.
        </p>
      </div>
      <div class="logo">
        <img src="../assets/img/LogoLow.png" alt="Hey Jura Logo" />
      </div>
    </div>
    <div class="links">
      <span>
        <router-link to="/imprint">Impressum</router-link>
      </span>
      <span>
        <router-link to="/goals">Unser Ziel</router-link>
      </span>
      <span>
        <router-link to="/contact">Kontakt</router-link>
      </span>

      <!--
      <span>
        <router-link to="/copyright">Urheberrecht</router-link>
      </span>
      -->

      <!--
      <span>Quellen</span>
      -->

      <span>
        <router-link to="/privacy">Datenschutz</router-link>
      </span>
      <span>
        <a target="__blank" href="https://www.instagram.com/heyjura.de/">
          <img src="../assets/img/instagram.png" alt="Instagram" />
        </a>
      </span>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

footer {
  padding: 10px 40px;

  @media screen and (max-width: 640px) {
    padding: 10px 2em;
  }

  background: white;
  color: $darkBlue;

  .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }

  .text {
    max-width: 800px;
    @media screen and (max-width: 640px) {
      max-width: 100%;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      max-width: 128px;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    span {
      margin-right: 24px;
      font-weight: bold;
      cursor: pointer;

      @media screen and (max-width: 640px) {
        font-size: 14px;
      }

      a {
        text-decoration: none;
        color: $darkBlue;
      }
    }

    img {
      width: 1.5em;
    }
  }

  @media screen and (max-width: 640px) {
    h2 {
      font-size: 1.7em;
    }
  }
}
</style>

<script>
export default {
  name: 'Footer',
};
</script>
