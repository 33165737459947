<template>
  <div>
    <section class="section-1">
      <h1>Schematische Suche</h1>

      <div>
        <FullHierarchySearch />
      </div>
    </section>

    <HomeContent />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

h1,
h2 {
  margin: 60px 0px;
  font-size: 3.0em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.7em;
    margin: 15px 0px;
  }
}

.blue {
  color: $blue;
  display: block;
}

.section-1 {
  div {
    margin-bottom: 100px;
  }
}
</style>

<script>
import FullHierarchySearch from "../components/FullHierarchySearch.vue";
import HomeContent from "../components/HomeContent.vue";

export default {
  name: "FullSearch",
  components: {
    FullHierarchySearch,
    HomeContent,
  },
};
</script>
