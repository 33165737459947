<template>
    <div class="wrapper flex" v-if="!this.isClicked">
      <div class="card">
        <div class="flex">
            <p>😇</p>
          Klicke bitte oben rechts in der Link-Leiste auf die drei Punkte (●●●) und dann auf "Im Browser öffnen". So funktioniert heyJura auch über Instagram.
          <!-- <button @click="accept">Ok</button> -->
        </div>
      </div>
    </div>
  </template>
  
  <style lang="scss" scoped>
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7em;
  }
  
  .wrapper {
    position: fixed;
    top: 1em;
    z-index: 1;
    width: 100%;
    
    @media screen and (max-width: 845px) {
        bottom: 0em;
      }
  }
  
  img {
    height: 2em;
  }
  
  .card {
    background: white;
    text-align: center;
    padding: 4em 1em;
    font-size: 1em;
  
    border-radius: 2em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  
    .flex {
      flex-wrap: wrap;
    }
    
    @media screen and (max-width: 845px) {
        border-radius: 0px;
        padding: 2em;
        box-shadow: 0px -10px 45px 0 rgb(0 0 0 / 10%);
      }
  }
  
  button {
    font-size: 0.9em;
    padding: 0.25em 1em;
  }
  </style>
  
  <script>
  export default {
    name: "InstagramAlert",
    data() {
    return {
      isClicked: false
    };
  },
    methods: {
      accept(){
        this.isClicked = true;
      },
    }
  }
  </script>
  
