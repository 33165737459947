import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import createPlugin from "logrocket-vuex";
import LogRocket from "logrocket";
import axios from "axios";

Vue.use(Vuex);

const logrocketPlugin = createPlugin(LogRocket);

export default new Vuex.Store({
  state: {
    basket: {
      items: [],
      lastUpdate: Date.now(),
    },
    cookies: null,
    apiKey: null,
    downloadCount: 0,
    user: null,
  },
  mutations: {
    removeFromBasket(state, item) {
      state.basket.items = state.basket.items.filter((i) => i._id != item._id);
      state.basket.lastUpdate = Date.now();
    },
    addToBasket(state, item) {
      state.basket.items.push(item);
      state.basket.lastUpdate = Date.now();
    },
    updateBasket(state, items) {
      state.basket.items = items;
      state.basket.lastUpdate = Date.now();
    },
    acceptCookies(state, item) {
      state.cookies = item;
      if (item.ga) window.ga(true);
      if (item.lr) LogRocket.init("3vyzuv/heyjura");
    },
    setAdminAPIKey(state, key) {
      state.apiKey = key;
    },
    setDownloadCount(state, count) {
      state.downloadCount = count;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async init({commit}) {

      return axios.get(`${process.env.VUE_APP_BACKEND_URL}/auth/login/success`
      ,{
        withCredentials: true,
      }
      ).then((response) => {
          if (response.status === 200) return response.data;
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          console.log(resObject)
          commit('setUser', resObject.user);
        })
        .catch((err) => {
          console.log("error", err);
        });
  }
  },
  getters: {
    getUser: (state) => state.user,
    isAuthenticated: (state) => !!state.user
  },
  plugins: [
    new VuexPersistence({ storage: window.localStorage }).plugin,
    logrocketPlugin,
  ],
});
