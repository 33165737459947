<template>
  <div>
    <!-- <section class="section-5">
      <h2>Print</h2>
      <div class="bottom">
        <div>
          <a href="https://www.print.heyjura.de/">
            <button @>MEHR INFOS</button>
          </a>
        </div>
      </div>
    </section> -->
    <section class="section-2">
      <h2>So einfach</h2>
      <div class="cards">
        <div>
          <span class="counter">1</span>
          <FlatIcon icon="adjustment" />
          <h3>Suchen und Finden</h3>
          <p>
            Suche Dir aus, was Du lernen möchtest.
            <b>Definitionen und Schemata</b> aus Zivil-, Straf- und Öffentlichem
            Recht vom <b>1. Semester</b> bis zum <b>1. Staatsexamen.</b>
          </p>
        </div>
        <div>
          <span class="counter">2</span>
          <FlatIcon icon="basket" />
          <h3>Individualität</h3>
          <p>
            Lege Schemata und Definitionen in den Korb und
            <b>individualisiere</b> Dein Inhaltsverzeichnis.
          </p>
        </div>
        <div>
          <span class="counter">3</span>
          <FlatIcon icon="download" />
          <h3>Download</h3>
          <p>
            Lade Dir Dein eigenes Skript <b>kostenlos</b> herunter und lerne wo
            und wann Du willst.
          </p>
        </div>
      </div>
    </section>

    <section class="section-3">
      <div class="main">
        <div v-if="!isMobile()">
          <PhoneVideo />
        </div>
        <div>
          <h2>So schnell</h2>
          <p>Klick.</p>
          <p>Klick.</p>
          <p>Und Fertig.</p>
        </div>
      </div>
      <div class="bottom">
        <div>
          <button @click="scrollTop()">LOS GEHT'S</button>
        </div>
        <div>
          <p>Kostenlos</p>
        </div>
      </div>
    </section>
    <section class="section-4">
      <h2 class="title-demo">Demo-Skript</h2>
      <div class="main">
        <a href="/heyJura_Demo-Skript.pdf" download="heyJura_Demo-Skript.pdf">
          <img
            class="demo-logo"
            src="../assets/img/Demo-Skript-Icon_centered_02.png"
          />
        </a>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

h2 {
  margin: 100px 0px;
  font-size: 3em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.75em;
  }
}

.blue {
  color: $blue;
  display: block;
}

.section-2 {
  background: white;
  padding: 20px 0px;

  .cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: $darkBlue;

    @media screen and (max-width: 640px) {
      padding: 0em 1em;
    }

    div {
      position: relative;
      max-width: 22em;
      text-align: center;
      margin-bottom: 4em;

      h3 {
        color: $blue;
        font-size: 25px;

        @media screen and (max-width: 640px) {
          font-size: 1.25em;
        }
      }

      span {
        position: absolute;
        left: 25px;
        top: 10px;
      }

      @media screen and (max-width: 640px) {
        p {
          font-size: 16px;
        }
        .title-demo {
          margin: 0 !important;
        }
      }
    }
  }
}

.section-3 {
  .main {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    margin-bottom: 100px;

    h2 {
      text-align: left;
      margin-bottom: 40px;

      @media screen and (max-width: 640px) {
        font-size: 1.7em;
        margin: 25px;
      }
    }

    p {
      margin: 0px;
      color: $darkBlue;
      font-size: 21px;

      @media screen and (max-width: 640px) {
        font-size: 1.25em;
      }
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }
  .bottom {
    margin-bottom: 100px;
    padding-top: 1em;

    div {
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: 640px) {
      margin: 60px;
    }
  }
  button {
    @media screen and (max-width: 640px) {
      padding: 15px 55px;
    }
  }
}

.section-4 {
  background: white;
  padding: 20px 0px;
  .main {
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
  }
}

.section-4 h2 {
  margin: 100px 0px 80px 0px;
}

.demo-logo {
  width: 180px;

  @media screen and (max-width: 640px) {
    width: 120px;
  }
}
.section-5 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  background: url(../assets/img/print_page.jpg) no-repeat center center / cover;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.3);
  padding: 20px 0px;
  // display: flex;

  h2 {
    margin: 0px 0px 0;
    font-size: 8em;
    text-align: center;
    color: $darkBlue;
    
    @media screen and (max-width: 640px) {
      font-size: 4em;
    }
  }

  p {
    max-width: 600px;
    text-align: center;
    padding: 1em 4em 1em;
    margin: 20px 0;
    color: $darkBlue;
    font-size: 21px;

    @media screen and (max-width: 640px) {
      font-size: 1.25em;
    }
  }
  button {
    background-color: #a4dda2;
    text-align: center;
    color: #fff;
    padding: 18px 65px;
    border: none;
    border-radius: 33px;
    font-size: 20px;
    cursor: pointer;
    transition: transform .3s;
    // margin-bottom: 50px;
    
    @media screen and (max-width: 640px) {
      padding: 14px 45px;
      font-size: 18px;
    }
  }
  
  button:hover {
    background-color: #ade9ab;
  }

  @media screen and (max-width: 640px) {
    min-height: 400px;
    h2 {
      font-size: 4em;
    }
  }
}
</style>

<script>
import FlatIcon from '../components/FlatIcon.vue';
const PhoneVideo = () => import('../components/PhoneVideo.vue');

export default {
  name: 'HomeContent',
  components: {
    FlatIcon,
    PhoneVideo,
  },
  methods: {
    scrollTop() {
      scrollTo({ top: 0, behavior: 'smooth' });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
