<template>
  <div class="main">
    <VueBotUI
      :options="botOptions"
      :messages="messageData"
      :bot-typing="botTyping"
      :input-disable="inputDisable"
      :is-open="false"
      @init="botStart"
      @msg-send="msgSend"
    />
  </div>
</template>

<script>
import { VueBotUI } from "vue-bot-ui";
import { messageService } from "../helpers/message";

export default {
  name: "Chatbot",
  props: [],
  data() {
    return {
      messageData: [],
      botTyping: false,
      inputDisable: false,
      botOptions: {
        colorScheme: "#4285F4",
        boardContentBg: "#f4f4f4",
        msgBubbleBgBot: "#fff",
        inputPlaceholder: "Type a message",
        inputDisableBg: "#fff",
        inputDisablePlaceholder: "Hit the buttons above to respond",
      },
    };
  },
  methods: {
    botStart() {
      this.botTyping = true;
      setTimeout(() => {
        this.botTyping = false;
        this.messageData.push({
          agent: "bot",
          type: "button",
          text: "Hallo! Ich helfe dir beim lernen - wie möchtest du anfangen?",
          'options': [
            {
              'text': 'Ich habe mein eigenes Text',
              'value': 'search',
              'action': 'postback'
            },
            {
              'text': 'Ich habe ein Thema oder Themabereich',
              'value': 'submit_ticket',
              'action': 'postback'
            },
            {
              'text': 'Wähle ein Thema für mich',
              'value': 'submit_ticket',
              'action': 'postback'
            }
          ],
        });
      }, 600);
    },

    msgSend(value) {
      this.messageData.push({
        agent: "user",
        type: "text",
        text: value.text,
      });
      this.getResponse(value.text);
    },

    async getResponse(message) {
      this.botTyping = true;
      const response = await messageService.createMessage(message);
      const replyMessage = {
        agent: "bot",
        ...response,
      };
      this.inputDisable = response.disableInput;
      this.messageData.push(replyMessage);
      this.botTyping = false;
    },
  },
  watch: {},
  computed: {
    rndPlaceholder() {
      return "Say hi!";
    },
  },
  components: { VueBotUI },
};
</script>
