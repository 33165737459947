<template>
  <div class="search">
    <SearchBox
      class="searchBox"
      @searchQueryChange="onSearchQueryChange"
      :hierarchySearch="true"
    />
    <div class="searchResults">
      <spinner v-if="!searchResults.length"></spinner>
      <FullHierarchySearchResult
        v-for="searchResult in processedSearchResults"
        :key="searchResult.id"
        :data="searchResult"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search {
  padding: 0px 4em;

  @media screen and (max-width: 640px) {
    padding: 0px 2em;
  }
}

.searchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>

<script>
// import axios from 'axios';
import SearchBox from '../components/SearchBox.vue';
import FullHierarchySearchResult from '../components/FullHierarchySearchResult.vue';

export default {
  name: 'FullHierarchySearch',
  data() {
    return {
      searchResults: [],
      searchQuery: {},
      searchType:
        this.type == 'cases' ? 'casesFullHierarchy' : 'searchFullHierarchy',
    };
  },
  components: {
    SearchBox,
    FullHierarchySearchResult,
  },
  props: ['type'],
  computed: {
    processedSearchResults() {
      let filteredResults = [];

      if (this.searchResults.length) {
        // Filter searchResults based on event type
        if (this.searchQuery.type == 'Definition') {
          filteredResults = this.searchResults.filter((result) =>
            result.codes.some((code) =>
              code.books.some((book) => book.topics && book.topics.length > 0)
            )
          );
        } else if (this.searchQuery.type == 'Schema') {
          filteredResults = this.searchResults.filter((result) => {
            console.log(result);
            result.codes.some((code) =>
              code.books.some(
                (book) => book.schemata && book.schemata.length > 0
              )
            );
          });
        } else {
          // Return all results if type is not specified or invalid
          filteredResults = this.searchResults;
        }

        return filteredResults.map((result) => this.addTypeToObject(result));
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.onSearchQueryChange({});
  },
  methods: {
    async onSearchQueryChange(event) {
      this.searchQuery = event;
      await this.$api
        .post(`/search/${this.searchType}`, { params: {} })
        .then((response) => {
          const transformedData = response.data;
          console.log(transformedData);
          this.searchResults = transformedData;
        })
        .catch((error) => {
          console.log(error);
          this.searchResults = [];
          this.$toast.open({
            message: 'Suche fehlgeschlagen',
            type: 'error',
          });
        });
    },
    addTypeToObject(obj) {
      const keyword = 'content';

      obj.codes.forEach((code) => {
        code.type = 'code';

        code.books.forEach((book) => {
          book.type = 'book';

          // add type property to topics and schemata objects
          const addTypeToDefinitions = (definitions) => {
            definitions.forEach((definition) => {
              definition.type = 'definition';
            });
          };

          // define new content array
          const content = [];

          if (book.topics) {
            book.topics.forEach((topic) => {
              topic.type = 'topic';
              addTypeToDefinitions(topic.definitions);

              //change array name
              topic[keyword] = topic.definitions;
              delete topic.definitions;
            });
            // merge topics into a new content array
            content.push(...book.topics);
          }

          if (book.cases) {
            book.cases.forEach((lawCase) => {
              lawCase.type = 'case';
            });
            // merge cases into a new content array
            content.push(...book.cases);
          }

          if (book.schemata) {
            book.schemata.forEach((schema) => {
              schema.type = 'schema';
              addTypeToDefinitions(schema.definitions);

              //change array name
              schema[keyword] = schema.definitions;
              delete schema.definitions;
            });
            // merge schemata into a new content array
            content.push(...book.schemata);
          }

          // sort content array in alphabetical order based on title property
          content.sort((a, b) => {
            if (a.title && b.title) {
              return a.title.localeCompare(b.title);
            } else if (a.title) {
              return -1;
            } else {
              return 1;
            }
          });

          // merge content back into books object
          book[keyword] = content;
          delete book.topics;
          delete book.schemata;
          delete book.cases;
        });

        //change array name
        code[keyword] = code.books;
        delete code.books;
      });

      //change array name
      obj[keyword] = obj.codes;
      delete obj.codes;

      return obj;
    },
  },
};
</script>
