<template>
  <div class="item">
    <span v-if="data.children.length" @click="preview(data)">
      <Arrow :direction="showChildren ? 'up' : 'down'" />
    </span>
    <div class="flex">
      <div>
        <h3 @click="preview(data)">{{ data.name }}</h3>
        <h4 v-if="data.children.length == 0">{{ data.type }}</h4>
        <h4 v-else>
          {{ data.type == "Definition" ? "Defintionen" : "Schemata" }}
        </h4>
      </div>
      <AddToBasketBtn :item="data" v-if="data.level < 4" />
    </div>

    <p v-if="data.description">{{ data.description }}</p>

    <div
      v-if="data.children.length"
      class="children"
      :class="{ show: showChildren }"
    >
      <div v-for="(child, index) in data.children" :key="index">
        <div class="flex">
          <h3 @click="preview(child)">{{ child.name }}</h3>
        </div>
        <p>{{ child.description }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

.item {
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  color: $darkBlue;
  margin-left: 35px;
  margin-bottom: 1em;

  h3 {
    font-size: 1.5em;
    margin: 0px;
    cursor: pointer;
    user-select: none;
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }

  p {
    margin: 0px;
    width: 90%;
  }

  span {
    position: absolute;
    left: -35px;
    top: 3px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .children {
    display: none;

    & > div {
      margin-top: 1em;
    }

    h3 {
      font-size: 1.25em;
    }
  }

  .show {
    display: block;
    margin-bottom: 2em;
  }
}
</style>

<script>
import Arrow from "./Arrow.vue";
import AddToBasketBtn from "./AddToBasketBtn.vue";

export default {
  name: "SearchResult",
  components: {
    Arrow,
    AddToBasketBtn
  },
  props: ["data", "closed"],
  data() {
    return {
      showChildren: !this.closed,
    };
  },
  methods: {
    preview(document) {
      if (document.type == "Schema") {
        this.$router.push("/schema/" + document._id);
        return;
      }

      this.showChildren = !this.showChildren;
    },
  },
};
</script>


