<template>
  <section>
    <h1>
      Per
      <span class="blue">Drag &amp; Drop </span> individualisieren. <br /><span
        class="blue"
        >Dann als Skript downladen.</span
      >
      Kostenlos.
    </h1>
    <div v-if="this.$store.state.basket.items.length">
      <div class="center">
        <Draggable
          v-model="basketItems"
          group="items"
          @start="drag = true"
          @end="drag = false"
          ghost-class="ghost"
        >
          <BasketItem
            v-for="(item, index) in basketItems"
            :key="item._id"
            :data="item"
            :index="index"
          />
        </Draggable>
      </div>
      <div class="downloadBtn">
        <button @click="downloadPDF" :disabled="loading">Download</button>
      </div>
      <span class="freeReminder">Kostenlos</span>
    </div>
    <div v-else>
      <h2>Dein Warenkorb ist leer</h2>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

section {
  margin-bottom: 1em;
  padding: 1em;

  span {
    color: $blue;
  }

  h1 {
    margin-bottom: 60px;
    font-size: 3em;
    text-align: center;
    color: $darkBlue;

    @media screen and (max-width: 640px) {
      font-size: 1.7em;
    }
  }

  h2 {
    text-align: center;
    color: $darkBlue;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .downloadBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2em;

    button:disabled {
      opacity: 0.6;
    }
  }

  .freeReminder {
    display: flex;
    justify-content: center;
    color: $darkBlue;
  }
}
</style>

<script>
import axios from "axios";
import Draggable from "vuedraggable";
import BasketItem from "../components/BasketItem.vue";
import { downloadB64PDF } from "../assets/js/util";

export default {
  name: "Basket",
  components: {
    Draggable,
    BasketItem,
  },
  data() {
    return {
      loading: false,
      loader: null,
    };
  },
  methods: {
    async downloadPDF() {
      if (this.loading) return;

      if (this.$store.state.downloadCount >= 20) {
        this.$toast.open({
          message: "Maximale Downloads in der aktuellen Beta-Phase erreicht",
          type: "error",
        });
        return;
      }


      this.loader = this.$loading.show({});
      this.loading = true;
      console.log("requesting pdf...");

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      const data = (
        await axios.post(process.env.VUE_APP_BACKEND_URL + "/buildPDF", {
          ids: this.$store.state.basket.items.map((i) => i._id),
          legacyDownload: isSafari,
        },{
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': 'http://localhost:8080',
            'Content-Type': 'application/json'},
        })
      ).data;
      this.loader.hide();

      if(data.url) {
        window.location.href = data.url;
        return;
      }

      if (data.success) {
        this.$store.commit("updateBasket", []);
        console.log("got pdf");
        this.$toast.open("PDF erfolgreich erstellt");

        this.$store.commit(
          "setDownloadCount",
          this.$store.state.downloadCount +
            this.$store.state.basket.items.length
        );

        if (isSafari) {
          window.location.href = `${process.env.VUE_APP_BACKEND_URL}/download/${data.fileName}`;
        } else {
          downloadB64PDF(data.file, "HeyJura_Download.pdf");
        }
      } else {
        this.$toast.open({
          message: "Erstellen der PDF fehlgeschlagen",
          type: "error",
        });
      }
      this.loading = false;
    },
  },
  computed: {
    basketItems: {
      get() {
        return this.$store.state.basket.items;
      },
      set(value) {
        this.$store.commit("updateBasket", value);
      },
    },
  },
};
</script>
