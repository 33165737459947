<template>
    <footer>
      <div class="flex">
        <h2 class="text">Unterstützt durch</h2>
        <section class="section-1">
         <div class="logos-wrapper">
            <div>
            <img

              src="../assets/img/Be_Berlin_Logo.png"
              alt="berlin-logo"
            />
          </div>
          <div>
            <img 

            src="../assets/img/BHT_Logo.png" alt="bht-logo" />
          </div>
         </div>

         <div class="logos-wrapper">
            <div>
            <img 

            src="../assets/img/logo-ESF-1-500x233.jpg" alt="esf-logo" />
          </div>
          <div>
            <img

              src="../assets/img/EU_ESI-Fonds_rechts.jpg"
              alt="eu-esi-logo"
            />
          </div>
         </div>

        </section>
      </div>
    </footer>
  </template>
  <style lang="scss" scoped>
  @import "../assets/css/_variables";
  .section-1 {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 80px;
  }
  .logos-wrapper {
    display: flex;
    flex-grow: 0.15;
    justify-content: space-between;
    align-items: center;
  }
  .flex {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 640px) {
      padding: 10px 2em;
    }
    color: $darkBlue;
    .text {
      margin: 100px 0;
      font-size: 3em;
      display: flex;
      flex-direction: column;
    }
    .text {
      text-align: center;
      @media screen and (max-width: 640px) {
        max-width: 100%;
      }
    }

    img {
        width: 10em;
        @media screen and (max-width: 640px) {
        width: 8em;
        }
    }
    @media screen and (max-width: 640px) {
      .text {
        font-size: 1.75em;
      }
    }
  }
    @media screen and (max-width: 640px) {
      .esf-logo {
        margin-top: 0.6em;
      }
    }
    @media screen and (max-width: 640px) {
       .bth-logo {
        margin-top: 0.6em;
      }
    }
  @media screen and (max-width: 640px) {
    .flex ul {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      margin-bottom: 100px;
    }
    .flex li {
      height: 20vh;
    }
    @media screen and (max-width: 768px) {
        .section-1 {
            gap: 40px;
            flex-direction: column;
            align-items: center;
        }
        .logos-wrapper {
            display: flex;
            gap: 40px;
        }
    }
    @media screen and (max-width: 320px) {
        .section-1 {
            gap: 5px;
        }
        .logos-wrapper {
            flex-direction: column;
            gap: 5px;
        }
    }
  }
  </style>
  
  <script>
  export default {
    name: "PreFooter",
  };
  </script>
