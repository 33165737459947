import { ChatOpenAI } from "langchain/chat_models/openai";
import { HumanChatMessage, SystemChatMessage } from "langchain/schema";

const { systemMessageV2Jackson } = require("../assets/js/prompts");
const chatbotModel = new ChatOpenAI({
  openAIApiKey: process.env.VUE_APP_OPENAI_API_KEY,
  modelName: "gpt-4",
  temperature: 1.15,
});

const systemMessage = new SystemChatMessage(systemMessageV2Jackson);
export const messageService = {
  createMessage,
};

async function createMessage(message) {
  const humanChatMessage = new HumanChatMessage(message);
  const response = await chatbotModel.call([systemMessage, humanChatMessage]);
  return response
}