<template>
  <div class="item" ontouchstart="">
    <div class="left">
      <span class="counter">{{ index + 1 }}</span>
      <div>
        <h3>{{ data.name }}</h3>
        <h4>{{ data.type == "Definition" ? "Defintionen" : "Schema" }}</h4>
      </div>
    </div>
    <div class="icon" >
      <FlatIcon icon="trash" @click.native="deleteItem()" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

.ghost {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
}

.item {
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: none;
  border-radius: 10px;
  max-width: 600px;
  color: $darkBlue;
  margin-bottom: 30px;
  gap: 15px;

  cursor: all-scroll;
  user-select: none;
  
  @media screen and (max-width: 640px) {
      gap: 0px;
      }

  h3 {
    font-size: 1.5em;
    margin: 0px;
    
    @media screen and (max-width: 640px) {
      font-size: 1.3em;
      }
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
    
    @media screen and (max-width: 640px) {
      font-size: 0.9em;
      }
  }
  
  .left {
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 20px;
  }

  .icon {
    padding: 20px 20px 20px 5px;
    cursor: pointer;
  }

  .counter {
    display: inline-block;
    color: $blue;
    background: white;
    border: 2px solid $blue;
    min-width: 32px;
    
    @media screen and (max-width: 640px) {
      font-size: 17px;
      height: 26px;
      width: 26px;
      min-width:26px;
      }
  }

  transition: box-shadow 0.3s;
}
  
</style>

<script>
import FlatIcon from "../components/FlatIcon.vue";

export default {
  name: "BasketItem",
  components: {
    FlatIcon,
  },
  props: ["data", "index"],
  methods: {
    deleteItem() {
      this.$store.commit("removeFromBasket", this.data);
    },
  },
};
</script>
