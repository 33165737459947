<template>
  <div>
    <div class="flex" v-show="!hierarchySearch">
      <input
        type="text"
        :placeholder="rndPlaceholder"
        :value="bouncedSearchQuery"
        @input="(e) => (bouncedSearchQuery = e.target.value)"
        @click="inputClicked"
      />
    </div>

    <div class="types">
      <div class="flex">
        <span v-if="!hierarchySearch" @click="$router.push('/casesFullSearch')"
          >Fälle</span
        >
        <span v-if="!hierarchySearch" @click="$router.push('/fullSearch')"
          >Schematische Suche</span
        >
      </div>

      <div class="flex" v-show="searchQuery != '' || hierarchySearch">
        <span
          :class="{ active: type == 'Schema' }"
          @click="switchType('Schema')"
          >Schemata</span
        >
        <span
          :class="{ active: type == 'Definition' }"
          @click="switchType('Definition')"
          >Definitionen</span
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

span {
  opacity: 0.4;
  margin: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s;

  &:hover {
    opacity: 1;
    color: #4285f4;
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}

.active {
  opacity: 1;
  color: #4285f4;
}

.types > .flex {
  margin: 0;
}

input {
  font-size: 1.25em;

  border: none;
  border-radius: 50px;
  max-width: 500px;
  flex-basis: auto;
  flex-grow: 1;
  padding: 25px 40px;

  transition: box-shadow 0.4s;

  outline: none;

  -webkit-appearance: none;
  box-shadow: 0 4px 5px 0 #e8e8e8, 0 6px 20px 0 #e8e8e8;

  &:focus {
    box-shadow: 0 4px 5px 0 #bebebe, 0 6px 20px 0 #bebebe;
  }

  @media screen and (max-width: 640px) {
    padding: 18px 19px;
    font-size: 1.2em;

    &::placeholder {
      font-size: 0.8em;
    }
  }
}
</style>

<script>
export default {
  name: 'SearchBox',
  props: ['hierarchySearch', 'caseHierarchySearch'],
  data() {
    return {
      searchQuery: '',
      timeout: null,
      type: '',
    };
  },
  methods: {
    search() {
      this.$emit('searchQueryChange', {
        searchQuery: this.searchQuery,
        type: this.type,
      });
    },
    switchType(type) {
      if (type == 'Schema') this.type = 'Schema';
      if (type == 'Definition') this.type = 'Definition';
    },
    inputClicked() {
      //Check Mobile
      if (window.screen.width > 700) return;

      //Dirty Fix
      setTimeout(() => {
        const elem = this.$el.querySelector('input');
        const y = elem.getBoundingClientRect().top + window.pageYOffset - 25;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 200);
    },
  },
  watch: {
    searchQuery() {
      this.search();
    },
    type() {
      this.search();
    },
  },
  computed: {
    bouncedSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchQuery = val;
        }, 300);
      },
    },
    rndPlaceholder() {
      const placeholders = [
        'z.B. Wegnahme, Diebstahl oder § 242 StGB',
        'z.B. Meinungsfreiheit oder Art. 5 I GG',
        'z.B. EBV, Herausgabeanspruch oder § 985 BGB',
      ];

      return placeholders[Math.floor(Math.random() * placeholders.length)];
    },
  },
};
</script>
