<template>
    <div >
      <section class="section-1-login">
          <div class="wrapper-anmelden">
            <div class="wrapper-anmelden-text">
              <h1 class="anmelden-tittle">Anmelden</h1>
              <p class="anmelden-tittle-2">
                Melde Dich kostenlos und unkompliziert an.
              </p>
              <button class="login-button" @click="googleLogin()">
                <img
                  src="../assets/img/Google__G__Logo.svg"
                  alt="Google"
                  width="250px"
                  height="50px"
                  class="social-icon social-icon-google"
                />
                <span>Mit Google fortfahren</span>
              </button>
          </div>
        </div>
      </section>
    </div>
  </template>

  <style lang="scss">
  .wrapper-login {
    // border: 3px solid blue;
    background-color: #f5f7ff;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    // width: 90%;
    padding: 40px 5px;
    margin: 50px;
    gap: 30px;
  }
  .section-1-login {
    .wrapper-anmelden {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      width: 516px;
      height: 638px;
      border-radius: 8px;
      background-color: #fff;
      padding: 1em;
    }

    .wrapper-anmelden-text {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      background-color: #fff;
      button {
        margin-top: 40px;
      }
    }
    .anmelden-tittle
    { text-align: center;
      margin: 0;
      color: #002060;
      font-size: 2.5em;
    }
    .anmelden-tittle-2 {
      text-align: center;
      margin: 0;
      color: #002060;
    }
    .wrapper-login {
       display: flex;
      flex-direction: column;
      width: 362px;
      height: 278px;
      // border: 3px solid blue;
      border-radius: 8px;
      margin: auto;
      margin-top: 10px;
      background-color: #fff;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
    .wrapper-login-box {
       display: flex;
      flex-direction: column;
      width: 362px;
      height: 278px;
      // border: 3px solid blue;
      border-radius: 8px;
      margin: auto;
      margin-top: 10px;
      background-color: #fff;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
    .login-button {
      display: flex;
      align-items: center;
      /* border-radius: 8px; */
      width: 300px;
      height: 60px;
      font-size: 1em;
      background-color: #f5f7ff;
      color: #002060;
      transition: 0.3s;
      padding: 10px 38px !important;
    }
    .login-button:hover{
      background: #f2f2f2;
    }
    .social-icon{
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 1rem;
    }
    .social-icon-google{
      width: 1.3em;
      height: 1.4em;
      font-size: 20px;
    }
  }
  .section-2-login {
    display: flex;
    align-items: center;
  }
  .section-2-login {
    .wrapper-rates {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 362px;
      height: 278px;
      /* border: 3px solid blue; */
      border-radius: 8px;
      background-color: #fff;
    }
    .rates-text {
      text-align: center;
      color: #002060;
    }
    .rates-text-1 {
      font-size: 1.1em;
      color: #002060;
      text-align: justify;
    }
    .rates-text-2 {
      font-size: 1em;
      color: #002060;
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapper-login {
      flex-wrap: wrap;
      background: #f5f7ff;
      margin: 2em;
    }
  }
  @media screen and (max-width: 1024px) {
    .section-1-login{
     margin-top: 0;
      }
  }

  @media screen and (max-width: 1024px) {
    .wrapper-anmelden{
     width: 320px !important;
     margin-top: 0;
      }
  }
  </style>

  <script>
  export default {
    name: "Login",
    methods: {
      googleLogin() {
        window.open(`${process.env.VUE_APP_BACKEND_URL}/auth/google`, "_self");
      },
    },
  };
  </script>
