import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Basket from '../views/Basket.vue';
import Admin from '../views/Admin.vue';
import FullSearch from '../views/FullSearch.vue';
import CasesFullSearch from '../views/CasesFullSearch.vue';
import PageNotFound from '../views/PageNotFound.vue';
import SchemePreview from '../views/SchemePreview.vue';
import Definition from '../views/Definition.vue';
import Case from '../views/Case.vue';
import Imprint from '../views/Imprint.vue';
import Copyright from '../views/Copyright.vue';
import Goals from '../views/Goals.vue';
import Privacy from '../views/Privacy.vue';
import Contact from '../views/Contact.vue';
import Login from '../views/Login.vue';
import Terms from '../views/Terms';
import Dashboard from '../views/Dashboard.vue';
import TypeForm from '../views/TypeForm.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/fullSearch',
    name: 'FullSearch',
    component: FullSearch,
  },
  {
    path: '/casesFullSearch',
    name: 'CasesFullSearch',
    component: CasesFullSearch,
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/schema/:id',
    name: 'SchemePreview',
    component: SchemePreview,
  },
  {
    path: '/definition/:id',
    name: 'Definition',
    component: Definition,
  },
  {
    path: '/case/:id',
    name: 'Case',
    component: Case,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: Copyright,
  },
  {
    path: '/goals',
    name: 'Goals',
    component: Goals,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/registration-form',
    name: 'TypeForm',
    component: TypeForm,
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  if (to.fullPath === '/registration-form') {
    await store.dispatch('init');
    next();
  }
  if (to.fullPath === '/dashboard') {
    await store.dispatch('init');
    if (!store.getters.isAuthenticated || !store.getters.getUser.agreeOnTerms) {
      next('/');
    } else if (!store.getters.getUser.registrationFormCompleted) {
      next('/registration-form');
    } else {
      next();
    }
  } else if (to.fullPath === '/login') {
    if (store.getters.isAuthenticated) {
      next('/dashboard');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
